import css from "styled-jsx/css";

const fontFamily = "Noto Sans Thai";
export default css.global`
  @import url("https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400;600;700&display=swap");
  :root {
    --family-bold: "${fontFamily}"; /* ${fontFamily} */
    --family-normal: "${fontFamily}"; /* ${fontFamily} */
    --family-medium: "${fontFamily}"; /* ${fontFamily} */

    --color-primary-1: #cafef8;
    --color-primary-2: #a1fef3;
    --color-primary-3: #51fdea;
    --color-primary-4: #03f9df;
    --color-primary-5: #02a998;
    --color-primary-6: #015850;
    --color-primary-7: #01534c;
    --color-primary-8: #014e48;
    --color-primary-9: #014944;
    --color-primary-10: #01443f;
    --color-primary: var(--color-primary-6);

    --color-secondary-1: #beffee;
    --color-secondary-2: #a0ffe7;
    --color-secondary-3: #63ffd9;
    --color-secondary-4: #25ffcd;
    --color-secondary-5: #00e7b4;
    --color-secondary-6: #00aa86;
    --color-secondary-7: #009073;
    --color-secondary-8: #007760;
    --color-secondary-9: #005d4d;
    --color-secondary-10: #004438;
    --color-secondary: var(--color-secondary-6);

    --color-brand-1: var(--color-primary);
    --color-brand-2: var(--color-secondary);
    --color-brand-3: #016987;
    --color-brand-4: #778f9b;
    --color-brand-5: #f1f2f4;
    --color-brand-6: #35373c;
    --color-brand-7: #ffffff;

    --color-success-1: #beffee;
    --color-success-2: #a0ffe7;
    --color-success-3: #63ffd9;
    --color-success-4: #25ffcd;
    --color-success-5: #00e7b4;
    --color-success-6: #00aa86;
    --color-success-7: #009073;
    --color-success-8: #007760;
    --color-success-9: #005d4d;
    --color-success-10: #004438;
    --color-success: var(--color-success-6);

    --color-error-1: #fff1f0;
    --color-error-2: #ffccc7;
    --color-error-3: #ffa39e;
    --color-error-4: #ff7875;
    --color-error-5: #ff4d4f;
    --color-error-6: #f5222d;
    --color-error-7: #cf1322;
    --color-error-8: #a8071a;
    --color-error-9: #820014;
    --color-error-10: #5c0011;
    --color-error: var(--color-error-6);

    --color-warning-1: #fff2e8;
    --color-warning-2: #ffd8bf;
    --color-warning-3: #ffbb96;
    --color-warning-4: #ff9c6e;
    --color-warning-5: #ff7a45;
    --color-warning-6: #fa541c;
    --color-warning-7: #d4380d;
    --color-warning-8: #ad2102;
    --color-warning-9: #871400;
    --color-warning-10: #610b00;
    --color-warning: var(--color-warning-6);

    --color-info-1: #e6f7ff;
    --color-info-2: #bae7ff;
    --color-info-3: #91d5ff;
    --color-info-4: #69c0ff;
    --color-info-5: #40a9ff;
    --color-info-6: #1890ff;
    --color-info-7: #096dd9;
    --color-info-8: #0050b3;
    --color-info-9: #003a8c;
    --color-info-10: #002766;
    --color-info: var(--color-info-6);

    --color-background-primary: #f8f8f8;
    --color-background-secondary: var(--color-brand-5);

    --default-background-color: #fff; /* #fff */
    --text-primary: #4f4f4f;
    --text-secondary: #575757;
    --text-disabled: var(--color-brand-5);
    --primary-color: #015850;
    --primary2-color: #00aa86;
    --accent-color: #016987;
    --accent2-color: #778f9b;

    --color-gradient: linear-gradient(
      to left,
      var(--color-secondary),
      var(--color-primary)
    );
  }
`;
